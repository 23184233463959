import React from 'react';
import styles from './Card.module.scss';
import cx from 'classnames';
import { motion } from 'framer-motion';

enum CardStyles {
    DEFAULT,
    SQUARE,
}

enum CardShadow {
    DEFAULT,
    NONE,
    SMALL,
}

enum CardBorder {
    DEFAULT,
    BLACK_BORDER,
    GRAY_BORDER,
}

type CardProps = React.PropsWithChildren<{
    className?: string;
    style?: CardStyles;
    shadow?: CardShadow;
    border?: CardBorder;
    animate?: boolean;
}>;

const Card: React.FC<CardProps> = ({
        className,
        children,
        style = CardStyles.DEFAULT,
        shadow = CardShadow.DEFAULT,
        border = CardBorder.DEFAULT,
        animate = false,
    }) => {
    const ComponentToRender = animate ? motion.div : 'div';
    return (
        <ComponentToRender
            layout="size"
            className={cx(styles.card, className, {
                [styles.square]: style === CardStyles.SQUARE,
                [styles.noShadow]: shadow === CardShadow.NONE,
                [styles.smallShadow]: shadow === CardShadow.SMALL,
                [styles.blackBorder]: border === CardBorder.BLACK_BORDER,
                [styles.grayBorder]: border === CardBorder.GRAY_BORDER
            })}
        >
            {children}
        </ComponentToRender>
    );
}

export { Card, CardStyles, CardShadow, CardBorder };
export default Card;
