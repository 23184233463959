import React, { PropsWithChildren } from 'react';

import styles from './Typography.module.scss';
import cx from 'classnames';

enum TypographyTypes {
    DEFAULT,
    H1,
    H2,
    H3,
    H4,
    H5,
    BUTTON,
    CAPTION,
    TINY,
    LARGE
}

enum TypographyColors {
    DEFAULT,
    DARK_GRAY,
    BLACK,
    WHITE,
    ERROR
}

enum TypographyDecorations {
    DEFAULT,
    LINE_THROUGH,
    BOLD,
    ITALIC,
    UNDERLINE
}

interface TypographyProps extends PropsWithChildren {
    text?: string;
    type?: TypographyTypes;
    className?: string;
    color?: TypographyColors;
    decorations?: TypographyDecorations | TypographyDecorations[];
}

function getTypography({ type, text, children, color, className, decorations }: TypographyProps) {

    const decorationsArray = Array.isArray(decorations) ? decorations : [decorations];

    const otherClasses = {
        [styles.darkGray]: color === TypographyColors.DARK_GRAY,
        [styles.black]: color === TypographyColors.BLACK,
        [styles.white]: color === TypographyColors.WHITE,
        [styles.error]: color === TypographyColors.ERROR,
        [styles.lineThrough]: decorationsArray.includes(TypographyDecorations.LINE_THROUGH),
        [styles.bold]: decorationsArray.includes(TypographyDecorations.BOLD),
        [styles.underline]: decorationsArray.includes(TypographyDecorations.UNDERLINE),
        [styles.italic]: decorationsArray.includes(TypographyDecorations.ITALIC)
    };

    switch (type) {
        case TypographyTypes.DEFAULT:
            return <p className={cx(className, styles.body, otherClasses)}>{text}{children}</p>;
        case TypographyTypes.H1:
            return <h1 className={cx(className, styles.h1, otherClasses)}>{text}{children}</h1>;
        case TypographyTypes.H2:
            return <h2 className={cx(className, styles.h2, otherClasses)}>{text}{children}</h2>;
        case TypographyTypes.H3:
            return <h3 className={cx(className, styles.h3, otherClasses)}>{text}{children}</h3>;
        case TypographyTypes.H4:
            return <h4 className={cx(className, styles.h4, otherClasses)}>{text}{children}</h4>;
        case TypographyTypes.H5:
            return <h5 className={cx(className, styles.h5, otherClasses)}>{text}{children}</h5>;
        case TypographyTypes.BUTTON:
            return <p className={cx(className, styles.button, otherClasses)}>{text}{children}</p>;
        case TypographyTypes.CAPTION:
            return <p className={cx(className, styles.caption, otherClasses)}>{text}{children}</p>;
        case TypographyTypes.TINY:
            return <p className={cx(className, styles.tiny, otherClasses)}>{text}{children}</p>;
        case TypographyTypes.LARGE:
            return <p className={cx(className, styles.large, otherClasses)}>{text}{children}</p>;
        default:
            return null;
    }
}

const Typography: React.FC<TypographyProps> = ({
    text,
    children,
    className,
    type = TypographyTypes.DEFAULT,
    color = TypographyColors.DEFAULT,
    decorations = TypographyDecorations.DEFAULT
}) => {
    return getTypography({ type, text, children, className, color, decorations });
};

export { Typography, TypographyTypes, TypographyColors, TypographyDecorations };
export default Typography;
