import React, { useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as main from '../../actions/main';
import Button from '../../common/components/Button';
import Popup from '../../common/components/Popup';
import Preloader from '../../components/Preloader';

import styles from './ConfirmLocationFooter.module.scss';

const ConfirmLocationFooter = props => {
    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isGettingLocation, setIsGettingLocation] = useState(false);

    const user = useSelector(state => state.main.user);
    const isConfirmingStep = useSelector(state => state.steps.isConfirmingStep);

    const geoLocationFail = () => {
        setIsGettingLocation(false);
        setShowError(true);
    };
    const getLocation = () => {
        setIsGettingLocation(true);
        setShowError(false);

        if (navigator.geolocation) {
            const location_timeout = setTimeout(geoLocationFail, 10000);

            navigator.geolocation.getCurrentPosition(
                position => {
                    clearTimeout(location_timeout);
                    setIsGettingLocation(false);
                    showPosition(position);
                },
                () => {
                    clearTimeout(location_timeout);
                    geoLocationFail();
                },
            );
        } else {
            // Fallback for no geolocation
            geoLocationFail();
        }
    };

    const showPosition = position => {
        dispatch(main.getAddressByCoordinates(position.coords.latitude, position.coords.longitude));
    };

    const { confirmButtonHandler, enterLocationManually } = props;

    return (
        <div className="footer-buttons">
            {showError && (
                <p>
                    Couldn't grab your location, please double check you are allowing access to your location and try
                    again or use "Enter location manually"
                </p>
            )}
            <Button onClick={enterLocationManually} className={styles.manualLocationButton}>
                Enter location manually
            </Button>
            {!user.signingLocation && (
                <Button disabled={isGettingLocation} onClick={getLocation}>
                    Grab my location {isGettingLocation && <Preloader />}
                </Button>
            )}
            {!!user.signingLocation && (
                <Button
                    onClick={() => confirmButtonHandler({ address: user.signingLocation })}
                    disabled={isConfirmingStep}
                >
                    Continue
                </Button>
            )}
            <Popup isOpen={showPopup} button="Ok" close={() => setShowPopup(false)}>
                Your agent has been notified and will call you as soon as possible
            </Popup>
        </div>
    );
};

export default memo(ConfirmLocationFooter);
