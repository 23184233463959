import React from 'react';
import parse from 'html-react-parser';
import styles from './Typography.module.scss';
import cx from 'classnames';

export enum TypographyTypes {
    DEFAULT,
    H1,
    H2,
    H4,
    LARGE_TEXT,
    BLACK_TEXT,
    ERROR,
}

interface TypographyProps {
    text: string;
    type?: TypographyTypes;
    className?: string;
    lineThrough?: boolean;
}

function getTypography(type: TypographyTypes, text: string, className?: string, lineThrough?: boolean) {
    switch (type) {
        case TypographyTypes.DEFAULT:
            return <p className={cx(className, styles.text, { [styles.lineThrough]: lineThrough })}>{parse(text)}</p>;
        case TypographyTypes.H1:
            return <h1 className={cx(className, styles.h1, { [styles.lineThrough]: lineThrough })}>{parse(text)}</h1>;
        case TypographyTypes.H2:
            return <h2 className={cx(className, styles.h2, { [styles.lineThrough]: lineThrough })}>{parse(text)}</h2>;
        case TypographyTypes.H4:
            return <h4 className={cx(className, styles.h4, { [styles.lineThrough]: lineThrough })}>{parse(text)}</h4>;
        case TypographyTypes.LARGE_TEXT:
            return (
                <p className={cx(className, styles.largeText, { [styles.lineThrough]: lineThrough })}>{parse(text)}</p>
            );
        case TypographyTypes.BLACK_TEXT:
            return (
                <p className={cx(className, styles.blackText, { [styles.lineThrough]: lineThrough })}>{parse(text)}</p>
            );
        case TypographyTypes.ERROR:
            return <p className={cx(className, styles.error, { [styles.lineThrough]: lineThrough })}>{parse(text)}</p>;
        default:
            return null;
    }
}

/**
 * @deprecated - Use Typography from design system instead
 */
const Typography: React.FC<TypographyProps> = ({ text, type = TypographyTypes.DEFAULT, className, lineThrough }) => {
    return getTypography(type, text, className, lineThrough);
};

export default Typography;
