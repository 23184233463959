import { CUSTOM_LOGIN_SUCCESS, CUSTOM_LOGIN_FAILURE, CUSTOM_LOGIN_REQUEST } from './customDocument';
import { VerificationStatus } from '../types/UploadADoc';

export const DOCUMENT_LOGIN_REQUEST = 'DOCUMENT_LOGIN_REQUEST';
export const DOCUMENT_LOGIN_SUCCESS = 'DOCUMENT_LOGIN_SUCCESS';
export const DOCUMENT_LOGIN_FAILURE = 'DOCUMENT_LOGIN_FAILURE';

export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAILURE = 'UPDATE_DOCUMENT_FAILURE';

export const UPDATE_DOCUMENT_CLIENT = 'UPDATE_DOCUMENT_CLIENT';

export const DOCUMENT_COMPLETED = 'DOCUMENT_COMPLETED';

export const CLIENT_VERIFIED = 'CLIENT_VERIFIED';

const initState = {
    isLoggedIn: false,
    user: null,
    invite: null,
    accessToken: null,
    isTokenIdShift: false,
    document: null,
    client: null,
    isCompleted: false,
    errorMessage: null,
};

export default function documents(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case CUSTOM_LOGIN_REQUEST:
        case DOCUMENT_LOGIN_REQUEST: {
            newState.isLoggedIn = false;
            newState.isTokenIdShift = false;
            newState.document = null;
            newState.invite = null;
            newState.isCompleted = false;
            break;
        }
        case DOCUMENT_LOGIN_SUCCESS: {
            newState.smsToken = action.token.mobileToken;
            newState.refreshToken = action.result.data.refreshToken;
            newState.isLoggedIn = true;
            newState.isTokenIdShift = true;
            newState.errorMessage = '';
            newState.document = action.result.data.document;
            newState.client = action.result.data.client;
            newState.invite = action.result.data.invite;
            break;
        }
        // TODO: this is duplicating the state in the custom documnent reducer. We should consolidate it
        case CUSTOM_LOGIN_SUCCESS: {
            newState.smsToken = action.token;
            newState.isLoggedIn = true;
            newState.isTokenIdShift = true;
            newState.document = action.result.data.document;
            newState.invite = action.result.data.invite;
            newState.client = action.result.data.client;
            break;
        }
        case CUSTOM_LOGIN_FAILURE:
        case DOCUMENT_LOGIN_FAILURE: {
            newState.isLoggedIn = false;
            newState.isTokenIdShift = true;
            newState.errorMessage = action.error.response.data.error;
            break;
        }
        case DOCUMENT_COMPLETED: {
            newState.isCompleted = true;
            break;
        }
        case UPDATE_DOCUMENT_SUCCESS: {
            newState.document = action.result.data.document;
            newState.client = action.result.data.client;
            newState.invite = action.result.data.invite;
            break;
        }

        case UPDATE_DOCUMENT_CLIENT: {
            newState.client = action.updatedClient;
            break;
        }

        case CLIENT_VERIFIED: {
            newState.client.verifiedProofOfIdentity = VerificationStatus.VERIFIED;
            break;
        }
    }

    return newState;
}
