import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import classNames from 'classnames/bind';

import * as main from 'actions/main';
import Preloader from 'components/Preloader';

const cx = classNames.bind(require('sass/changeLocation.scss'));

class ModalLocationContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
            isChanging: false
        };

        this.getAddresses = debounce(this.getAddresses, 500);
    }

    render() {
        const { addresses, isLoading } = this.props;
        const { inputValue, isChanging } = this.state;

        return (
            <div className="change-location">
                <div className="change-location-header">
                    <button className="button-cancel" onClick={this.close.bind(this)} />
                    <button
                        className={cx('button-erase', { hidden: !inputValue })}
                        onClick={this.clearInput.bind(this)}
                    />
                </div>
                <div className="input-wrapper">
                    <input
                        type="text"
                        placeholder="Enter Suburb"
                        value={inputValue}
                        onChange={e => this.autoComplete(e)}
                    />
                </div>
                {isLoading && !!inputValue && <Preloader inverse={true} />}
                {!addresses.length &&
                    !!inputValue &&
                    !isLoading &&
                    !isChanging && (
                        <p className="no-results">
                            We can't find such location.<br />Try to change your search.
                        </p>
                    )}
                {!!addresses.length && (
                    <ul className="list">
                        {addresses.map((item, index) => {
                            const regexp = new RegExp(inputValue, 'gi');
                            const value = item.description.replace(
                                regexp,
                                `<span class="text-bold">${inputValue}</span>`
                            );

                            return (
                                <li
                                    className="item"
                                    key={index}
                                    onClick={() => this.selectAddress(item.description)}
                                    dangerouslySetInnerHTML={{ __html: value }}
                                />
                            );
                        })}
                    </ul>
                )}
            </div>
        );
    }

    autoComplete(event) {
        const value = event.target.value;
        if (!value) {
            return false;
        }

        this.setState({
            ...this.state,
            inputValue: value,
            isChanging: true
        });

        this.getAddresses(value);
    }

    getAddresses = value => {
        this.props.getAddresses(value);

        this.setState({
            ...this.state,
            isChanging: false
        });
    };

    selectAddress(address) {
        const { setLocation } = this.props;

        setLocation(address);

        this.close();
    }

    clearInput() {
        this.setState({
            ...this.state,
            inputValue: ''
        });
    }

    close() {
        this.props.close();
    }
}

export default connect(
    state => ({
        addresses: state.address.data,
        isLoading: state.address.isLoading
    }),
    {
        getAddresses: main.getAddresses,
        getCoordinates: main.getCoordinates,
        setLocation: main.setLocation
    }
)(ModalLocationContent);
