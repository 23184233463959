import { Card, CardShadow } from '../card/Card';
import { Icon, IconSize, Icons } from '../icon/Icon';
import { Typography, TypographyTypes } from '../typography/Typography';

import cx from 'classnames';
import styles from './Note.module.scss';
import React from 'react';

interface NoteProps {
    title?: string;
    showIcon?: boolean;
    className?: string;
    type?: NoteType;
    children: React.ReactNode;
}

export enum NoteType {
    INFO = 'info',
    WARN = 'warning',
    ERROR = 'error',
    SUCCESS = 'success',
}

const DEFAULT_ICONS: Record<NoteType, Icons> = {
    [NoteType.INFO]: Icons.INFO,
    [NoteType.WARN]: Icons.WARN,
    [NoteType.ERROR]: Icons.DANGER,
    [NoteType.SUCCESS]: Icons.CHECK,
};

export function Note({ title, showIcon = true, children, className, type = NoteType.INFO }: NoteProps) {
    return (
        <Card className={cx(styles.container, styles[type], className)} shadow={CardShadow.NONE}>
            {(!!title || showIcon) && (
                <div className={styles.header}>
                    {showIcon && (
                        <Icon
                            icon={DEFAULT_ICONS[type]}
                            size={IconSize.SMALL}
                            className={cx(styles.icon, {
                                [styles.infoIcon]: type === NoteType.INFO,
                            })}
                        />
                    )}
                    {!!title && <Typography type={TypographyTypes.H4} text={title} />}
                </div>
            )}
            <div className={styles.content}>{children}</div>
        </Card>
    );
}

export default Note;
