import { DOCUMENT_STATUS_DRAFT, INVITE_STATUS_CANCELLED, PdfGenerationStatus } from '../../config';
import { setErrorMessage } from '../../actions/main.js';
import hashHistory from '../../history.js';
import {
    ACKNOWLEDGE_VERB,
    buildUploadADocRoute,
    CONFIRMATION_TYPE_SIGN,
    SIGN_VERB,
    UploadADocRoutes,
} from '../../common/constants';
import { Client, UploadADoc, VerificationStatus } from '../../types/UploadADoc';
import { Invite } from '../../types';
import { Dispatch } from 'redux';
import { matchPath } from 'react-router';

const hasAcceptedInvite = (invite: Invite) => {
    return invite.nextStep !== 0;
};

export const shouldCompleteCustomPlaceholdersStep = (document: UploadADoc) => {
    if (document?.hasClientCustomPlaceholders) {
        return !document.enforceSequentialSigning || document.client?.currentSigningClientOrder === 1;
    }
    return false;
};

const hasCompletedCustomPlaceholdersStep = (document: UploadADoc) => {
    return document.pdfGeneratedState === PdfGenerationStatus.COMPLETED_WITH_CLIENT_PLACEHOLDERS;
};

export const redirectToCorrectPage = (
    document: UploadADoc,
    invite: Invite,
    client: Client,
    token: string,
    dispatch: Dispatch,
    isEditingPlaceholders?: boolean
) => {
    if (document && invite.status === INVITE_STATUS_CANCELLED) {
        dispatch(setErrorMessage('This document was cancelled, wait for the agent to send you a new one'));
        hashHistory.push('/error');
    } else if (document && document.status === DOCUMENT_STATUS_DRAFT) {
        dispatch(
            setErrorMessage('This document is currently being drafted, please wait until you receive a new message')
        );
        hashHistory.push('/error');
    } else if (document && invite && token && client) {
        const shouldRedirectToCustomPlaceholdersStep =
            shouldCompleteCustomPlaceholdersStep(document) &&
            (!hasCompletedCustomPlaceholdersStep(document) || isEditingPlaceholders);

        const shouldRedirectToVerifyIdentityStep =
            client.requireProofOfIdentity && client.verifiedProofOfIdentity !== VerificationStatus.VERIFIED;

        if (!hasAcceptedInvite(invite)) {
            if (!matchPath(hashHistory.location.pathname, { path: UploadADocRoutes.WELCOME, exact: true })) {
                hashHistory.push(buildUploadADocRoute(UploadADocRoutes.WELCOME, token));
            }
        } else if (shouldRedirectToVerifyIdentityStep) {
            if (!matchPath(hashHistory.location.pathname, { path: UploadADocRoutes.VERIFY, exact: true })) {
                hashHistory.push(buildUploadADocRoute(UploadADocRoutes.VERIFY, token));
            }
        } else if (shouldRedirectToCustomPlaceholdersStep) {
            if (!matchPath(hashHistory.location.pathname, { path: UploadADocRoutes.FORM, exact: true })) {
                hashHistory.push(buildUploadADocRoute(UploadADocRoutes.FORM, token));
            }
        } else if (
            !(
                matchPath(hashHistory.location.pathname, { path: UploadADocRoutes.DETAILS, exact: true }) ||
                matchPath(hashHistory.location.pathname, { path: UploadADocRoutes.SIGN, exact: true })
            )
        ) {
            hashHistory.push(buildUploadADocRoute(UploadADocRoutes.DETAILS, token));
        }
    }
};

export const getConfirmationVerb = (confirmationType: string) => {
    return confirmationType === CONFIRMATION_TYPE_SIGN ? SIGN_VERB : ACKNOWLEDGE_VERB;
};
