/**
 * NOTE: Please do not add icons to this file directly. Instead, use the import-icon npm script
 */
import * as React from 'react';
import cx from 'classnames';
import { ReactComponent as DocumentIcon } from './icons/documentIcon.svg';
import { ReactComponent as ChevronRightIcon } from './icons/chevronRightIcon.svg';
import { ReactComponent as CheckIcon } from './icons/checkIcon.svg';
import { ReactComponent as CloseIcon } from './icons/closeIcon.svg';
import { ReactComponent as InfoIconIcon } from './icons/infoIconIcon.svg';
import { ReactComponent as ExpandIcon } from './icons/expandIcon.svg';
import { ReactComponent as WitnessSignatureIcon } from './icons/witnessSignatureIcon.svg';
import { ReactComponent as ScrollDownIcon } from './icons/scrollDownIcon.svg';
import { ReactComponent as MouseScrollDownIcon } from './icons/mouseScrollDownIcon.svg';
import { ReactComponent as UploadImageIcon } from './icons/uploadImageIcon.svg';
import { ReactComponent as LinkIcon } from './icons/linkIcon.svg';
import { ReactComponent as CopyIcon } from './icons/copyIcon.svg';
import { ReactComponent as PhoneIcon } from './icons/phoneIcon.svg';
import { ReactComponent as DownloadIcon } from './icons/downloadIcon.svg';
import { ReactComponent as BackIcon } from './icons/backIcon.svg';
import { ReactComponent as FlkDocumentIcon } from './icons/flkDocumentIcon.svg';
import { ReactComponent as ErrorIcon } from './icons/errorIcon.svg';
import { ReactComponent as CampaignFlowIcon } from './icons/campaignFlowIcon.svg';
import { ReactComponent as ListNowIcon } from './icons/listNowIcon.svg';

import styles from './Icon.module.scss';

enum Icons {
    DOCUMENT,
    CHEVRON_RIGHT,
    CHECK,
    CLOSE,
    INFO_ICON,
    EXPAND,
    WITNESS_SIGNATURE,
    SCROLL_DOWN,
    MOUSE_SCROLL_DOWN,
    UPLOAD_IMAGE,
    LINK,
    COPY,
    PHONE,
    DOWNLOAD,
    BACK,
    FLK_DOCUMENT,
    ERROR_ICON,
    CAMPAIGN_FLOW,
    LIST_NOW,
}

enum IconSize {
    DEFAULT,
}

interface IconProps {
    icon: Icons;
    size?: IconSize;
    className?: string;
    onClick?: () => void;
}

const getIcon = (icon: Icons) => {
    switch (icon) {
        case Icons.DOCUMENT:
            return DocumentIcon;
        case Icons.CHEVRON_RIGHT:
            return ChevronRightIcon;
        case Icons.CHECK:
            return CheckIcon;
        case Icons.CLOSE:
            return CloseIcon;
        case Icons.INFO_ICON:
            return InfoIconIcon;
        case Icons.EXPAND:
            return ExpandIcon;
        case Icons.WITNESS_SIGNATURE:
            return WitnessSignatureIcon;
        case Icons.SCROLL_DOWN:
            return ScrollDownIcon;
        case Icons.MOUSE_SCROLL_DOWN:
            return MouseScrollDownIcon;
        case Icons.UPLOAD_IMAGE:
            return UploadImageIcon;
        case Icons.LINK:
            return LinkIcon;
        case Icons.COPY:
            return CopyIcon;
        case Icons.PHONE:
            return PhoneIcon;
        case Icons.DOWNLOAD:
            return DownloadIcon;
        case Icons.BACK:
            return BackIcon;
        case Icons.FLK_DOCUMENT:
            return FlkDocumentIcon;
        case Icons.ERROR_ICON:
            return ErrorIcon;
        case Icons.CAMPAIGN_FLOW:
            return CampaignFlowIcon;
        case Icons.LIST_NOW:
            return ListNowIcon;
        default:
            throw new Error('An invalid icon prop was passed to the Icon component.');
    }
};

/**
 * @deprecated Use the Icon component from @flk/design-system instead
 */
const Icon: React.FC<IconProps> = ({ icon, className, size = IconSize.DEFAULT, ...rest }) => {
    const IconComponent = getIcon(icon);

    return <IconComponent className={cx(styles.icon, className)} {...rest} />;
};

export default Icon;
export { Icons, IconSize };
