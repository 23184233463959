import { uniq } from 'lodash';
import { createSelector } from 'reselect';
import { getDocumentClient } from '../documents';
import { DeliveryType, UadSigningSteps } from '../../types/UploadADoc';
import { shouldCompleteCustomPlaceholdersStep } from '../../containers/custom_document/utils';

export const getCustomDocument = (state) => state.customDocumentReducer;

export const getToken = createSelector([getCustomDocument], (customDocument) => customDocument.smsToken);

export const getUploadedDocumentsMeta = createSelector(
    [getDocumentClient],
    (documentClient) => documentClient?.uploadedDocumentsMeta
);

export const getDocumentToSign = createSelector([getCustomDocument], (customDocument) => customDocument.documentToSign);
export const getIsEnforcedSigningOrder = createSelector(
    [getDocumentToSign],
    (documentToSign) => documentToSign?.enforceSigningOrder
);

export const getIsEnforceSequentialSigning = createSelector(
    [getDocumentToSign],
    (documentToSign) => documentToSign?.enforceSequentialSigning
);

export const getDocumentToSignIdOrNull = createSelector([getDocumentToSign], (documentToSign) => documentToSign?.id);

export const getDocumentToSignStatusOrNull = createSelector(
    [getDocumentToSign],
    (documentToSign) => documentToSign?.status
);

export const getDocumentToSignConfirmationTypeOrNull = createSelector(
    [getDocumentToSign],
    (documentToSign) => documentToSign?.confirmationType
);

export const getDocumentToSignType = createSelector([getDocumentToSign], (documentToSign) => documentToSign?.docType);

export const getDocumentToSignAccountTypeOrNull = createSelector(
    [getDocumentToSign],
    (documentToSign) => documentToSign?.agency?.accountType
);

export const getDocumentToSignClientsOrNull = createSelector(
    [getDocumentToSign],
    (documentToSign) => documentToSign?.client?.clients
);

export const getIsListNowEnabled = createSelector(
    [getDocumentToSign],
    (documentToSign) => documentToSign?.listNow?.enabled
);

export const getDocumentToSignAcknowledgementText = createSelector(
    [getDocumentToSign],
    (documentToSign) => documentToSign?.acknowledgementText
);

export const getDocumentToSignTitle = createSelector([getDocumentToSign], (documentToSign) => documentToSign?.title);

export const getIsMulti = createSelector(
    [getDocumentToSign],
    (documentToSign) => documentToSign?.uploadedDocuments?.length > 1
);

export const getClientSignaturePages = createSelector(
    [getDocumentToSign],
    (documentToSign) => documentToSign?.uploadedDocsClientSignaturePages
);

export const getDocumentInvite = createSelector([getCustomDocument], (customDocument) => customDocument.invite);

export const getCustomDocumentErrorMessage = createSelector(
    [getCustomDocument],
    (customDocument) => customDocument.errorMessage
);

export const getAddingSignatureFromMobile = createSelector(
    [getCustomDocument],
    (customDocument) => customDocument.isAddingSignatureFromMobile
);

export const getMobileSignatureAdded = createSelector(
    [getCustomDocument],
    (customDocument) => customDocument.isMobileSignatureAdded
);

export const getDocumentComplete = createSelector(
    [getCustomDocument],
    (customDocument) => customDocument.isDocumentComplete
);

export const getDesktopSigningStep = createSelector(
    [getCustomDocument],
    (customDocument) => customDocument.desktopSigningStep
);

export const getSelectedDocumentId = createSelector(
    [getCustomDocument],
    (customDocument) => customDocument.selectedDocumentId
);

export const getSelectedDocument = createSelector(
    [getDocumentToSign, getSelectedDocumentId],
    (documentToSign, selectedDocumentId) =>
        documentToSign?.uploadedDocuments.find((uploadedDocument) => uploadedDocument.id === selectedDocumentId)
);

export const getSelectedDocumentMeta = createSelector(
    [getUploadedDocumentsMeta, getSelectedDocumentId],
    (uploadedDocumentsMeta, selectedDocumentId) =>
        uploadedDocumentsMeta?.find((uploadedDocument) => uploadedDocument.uploadedDocumentId === selectedDocumentId)
);

export const getIsAnyDocumentRead = createSelector([getUploadedDocumentsMeta], (uploadedDocumentsMeta) =>
    uploadedDocumentsMeta?.some((uploadedDocument) => uploadedDocument.read)
);

export const getIsEveryDocumentRead = createSelector([getUploadedDocumentsMeta], (uploadedDocumentsMeta) =>
    uploadedDocumentsMeta?.every((uploadedDocument) => uploadedDocument.read)
);

export const getFirstUnreadDocumentIdOrNull = createSelector([getUploadedDocumentsMeta], (uploadedDocumentsMeta) => {
    return uploadedDocumentsMeta?.find((uploadedDocument) => !uploadedDocument.read)?.uploadedDocumentId || null;
});

export const getIsLastUnreadDocument = createSelector(
    [getSelectedDocumentId, getUploadedDocumentsMeta],
    (selectedDocumentId, uploadedDocumentsMeta) =>
        uploadedDocumentsMeta
            ?.filter((document) => document.uploadedDocumentId !== selectedDocumentId)
            .every((document) => document.read)
);

/**
 * Get the indexes of signature pages for the current client and document
 * @returns @type {number[]} Array of pages that have signatures, indexed starting from 0.
 */
export const getPagesWithSignaturesForCurrentClientAndDocument = createSelector(
    [getClientSignaturePages, getSelectedDocumentId, getDocumentClient],
    (signaturePages, documentId, client) =>
        uniq(signaturePages?.[documentId]?.[client.index] || [])
            .map((pageIndexString) => parseInt(pageIndexString))
            .sort((a, b) => a - b)
);

/**
 * Get the page numbers of signature pages for the current client and document
 * @returns @type {number[]} Array of page numbers that have signatures
 */
export const getPageNumbersWithSignaturesForCurrentClientAndDocument = createSelector(
    [getClientSignaturePages, getSelectedDocumentId, getDocumentClient],
    (signaturePages, documentId, client) =>
        uniq(signaturePages?.[documentId]?.[client.index] || [])
            .map((pageIndexString) => parseInt(pageIndexString) + 1)
            .sort((a, b) => a - b)
);

export const getIsEditingPlaceholders = createSelector(
    [getCustomDocument],
    (customDocument) => customDocument.isEditingPlaceholders
);

export const getIsQrCodeTemplate = createSelector(
    [getCustomDocument],
    (customDocument) => customDocument.isQrCodeTemplate
);

export const getSteps = createSelector(
    [getDocumentToSign, getDocumentClient, getIsQrCodeTemplate],
    (document, client, isQrCodeTemplate) => {
        const steps = [];

        if (client?.requireProofOfIdentity) {
            steps.push({
                title: 'Verify Identity',
                id: UadSigningSteps.VERIFY_IDENTITY,
            });
        }

        if (shouldCompleteCustomPlaceholdersStep(document) || isQrCodeTemplate) {
            steps.push({
                title: 'Required Info',
                id: UadSigningSteps.REQUIRED_INFO,
            });
        }

        steps.push({
            title: 'Review & Sign',
            id: UadSigningSteps.REVIEW_SIGN,
        });

        return steps;
    }
);
