import { ifFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { find, has, isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import hashHistory from './../history';

import 'animate.css/animate.min.css';
import 'sass/main.scss';

import * as tenantLogin from '../actions/main';

import { STEP_STATE_STATUS_CONFIRMED, STEP_STATE_STATUS_DECLINE } from '../reducers/steps';

import ChangeRequestInfo from 'containers/ChangeRequestInfo';
import Footer from '../components/Footer';
import Header from '../components/Header';
import PmFooter from '../components/PmFooter';
import Preloader from '../components/Preloader';
import SalesFooter from '../components/SalesFooter';
import ChangeRequest from '../components/modal/ChangeRequest';
import Navigation from '../components/modal/Navigation';
import NewChangeRequest from '../components/new_designs/ChangeRequest.tsx';

import Alert from 'components/modal/Alert';
import cookie from 'react-cookie';
import {
    checkIfChangeRequestsAreResolved,
    getStepViaName,
    isLeaseTypeCommercialLease,
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeSales,
    isMobileWidth,
    isTouchScreen,
} from 'utils';
import { SA_PM_DESKTOP_SIGNING } from '../common/featureFlags.ts';
import ContinueOnMobile from '../components/ContinueOnMobile.tsx';
import RotateScreen from '../components/RotateScreen.tsx';
import ChangeLocation from '../components/modal/ChangeLocation';
import { LEASE_TYPE_COMMERCIAL_LEASE, LEASE_TYPE_PROPERTY_MANAGEMENT, LEASE_TYPE_SALES, NSW_STATE } from '../config';
import { isLeaseLocationQLD, isLeaseLocationSA, isLocationBaseURL } from '../utils';
import TwilioSyncUpdates from './TwilioSyncUpdates.tsx';

const IMAGE = 'image';
const IMAGE_NEW = 'image-new';
const GRAY_BACKGROUND = 'gray-background';
const DECLINE = 'decline';
const CONFIRMED = 'confirmed';

const RotateScreenWithSaPmFlag = ifFeatureFlag(SA_PM_DESKTOP_SIGNING, { matchValue: false })(RotateScreen);

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalSign: false,
            showModalRequest: false,
            navigation: false,
            showModalLocation: false,
        };
    }

    // Handle resize so that scroll lock is not broken by a desktop window being resized
    handleResize = () => {
        // Attempts to avoid firing the handler for mobile device.
        // Mobile browsers initially render with toolbars. We store this height so we can lock scroll in correct position for signing
        // We check for mobile device by checking:
        //     - screen smaller than 800px,
        //     - has touch screen
        const mobileBreakpoint = 800;
        const isMobileDevice = isTouchScreen() && isMobileWidth(mobileBreakpoint);
        if (isMobileDevice) return;

        this.props.setInitialWindowHeight(window.innerHeight);
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        if (!this.props.initialWindowHeight) {
            this.props.setInitialWindowHeight(window.innerHeight);
        }

        if (!this.props.isTokenIdShift) {
            // this part is used when a user refreshes after the arriving
            const mobileToken = cookie.load('mobileToken');
            if (mobileToken) {
                this.props.login(mobileToken).then((data) => {
                    if (isLeaseTypePm(this.props.lease.leaseType)) {
                        this.props.getFullPmAgreementInformation(this.props.lease.location);
                    }
                });
            } else {
                hashHistory.push(`/error`);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    shouldComponentUpdate(nextProps) {
        // if user put a new url in same tab then reload and execute routes
        // this is useful in cypress automation
        if (nextProps.router.location.pathname.split('/').length === 2) {
            hashHistory.push(nextProps.router.location.pathname);
            return false;
        }
        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.showErrorPage && nextProps.showErrorPage) {
            // if we receive notice that something went wrong (link lease is invalid, for instance, when the agent has updated the lease agreement and we still
            // have the old link loaded and try to make a change), immediately redirect the user to an appropriate message.
            hashHistory.push(`/error`);
        }

        if (this.props.lease && nextProps.lease && this.props.lease.id !== nextProps.lease.id) {
            switch (this.props.lease.leaseType) {
                case LEASE_TYPE_SALES:
                    /**
                     * @todo only get this information if we dont have it
                     */
                    setTimeout(
                        () =>
                            this.props.getSalesAgreementInformation(
                                nextProps.lease.location,
                                this.props.lease.priceAndFee.saleMethod
                            ),
                        1000
                    );
                    break;
                case LEASE_TYPE_PROPERTY_MANAGEMENT:
                    /**
                     * @todo only get this information if we dont have it
                     */
                    setTimeout(() => this.props.getPmAgreementInformation(nextProps.lease.location), 1000);
                    setTimeout(() => this.props.getFullPmAgreementInformation(nextProps.lease.location), 1000);
                    break;
                case LEASE_TYPE_COMMERCIAL_LEASE:
                    setTimeout(() => this.props.getCommercialLeaseAgreementInformation(nextProps.lease.location), 1000);
                    break;
                default:
                    if (nextProps.lease.location !== NSW_STATE) {
                        /**
                         * @todo only get this information if we dont have it
                         */
                        // Seems like the cookie is saved synchronously, so we need a timeout to ensure it has been saved
                        // incase the previous token is now invalid
                        setTimeout(() => this.props.getLeaseAgreementInformation(), 1000);
                    }
            }
        }
    }

    // eslint-disable-next-line getter-return
    get steps() {
        if (this.props.lease) {
            switch (this.props.lease.leaseType) {
                case LEASE_TYPE_SALES:
                    return this.props.salesSteps;
                case LEASE_TYPE_PROPERTY_MANAGEMENT:
                    return this.props.pmSteps;
                default:
                    return this.props.steps;
            }
        }
    }

    // eslint-disable-next-line getter-return
    get currentStep() {
        if (this.props.lease) {
            if (isLeaseTypePm(this.props.lease.leaseType) && isLeaseLocationSA(this.props.lease.location)) {
                return this.props.commonSteps.find((item) => {
                    return this.matchPath(
                        item,
                        isLocationBaseURL(this.props.lease.leaseType, this.props.lease.location)
                    );
                });
            }
            switch (this.props.lease.leaseType) {
                case LEASE_TYPE_SALES:
                    return this.props.salesSteps.find((item) => {
                        return this.matchPath(
                            item,
                            isLocationBaseURL(this.props.lease.leaseType, this.props.lease.location)
                        );
                    });
                case LEASE_TYPE_PROPERTY_MANAGEMENT:
                    return this.props.pmSteps.find((item) => {
                        return this.matchPath(
                            item,
                            isLocationBaseURL(this.props.lease.leaseType, this.props.lease.location)
                        );
                    });
                default:
                    return this.props.steps.find((item) => {
                        return this.matchPath(
                            item,
                            isLocationBaseURL(this.props.lease.leaseType, this.props.lease.location)
                        );
                    });
            }
        }
    }

    /**
     * routes[2].path is the current active route
     * @param item
     * @returns {boolean}
     */
    matchPath(item, isLocationBaseURL) {
        const path = item.name;
        const routes = this.props.router.location.pathname.split('/');
        return path === routes[isLocationBaseURL ? 3 : 2];
    }

    // eslint-disable-next-line getter-return
    get currentStepNumber() {
        if (this.props.lease) {
            switch (this.props.lease.leaseType) {
                case LEASE_TYPE_SALES:
                    return this.props.salesSteps.findIndex((item) => {
                        return this.matchPath(
                            item,
                            isLocationBaseURL(this.props.lease.leaseType, this.props.lease.location)
                        );
                    });
                case LEASE_TYPE_PROPERTY_MANAGEMENT:
                    return this.props.pmSteps.findIndex((item) => {
                        return this.matchPath(
                            item,
                            isLocationBaseURL(this.props.lease.leaseType, this.props.lease.location)
                        );
                    });
                default:
                    return this.props.steps.findIndex((item) => {
                        return this.matchPath(
                            item,
                            isLocationBaseURL(this.props.lease.leaseType, this.props.lease.location)
                        );
                    });
            }
        }
    }

    /**
     *  Hack added to skip step 14 for Queensland witness signature no longer needed
     *  check server code you'll understand, sigh
     * @returns {*}
     */
    nextStep(lease) {
        let nextStep;
        let reducerSteps;
        let redirectStep;
        let isRedirect;
        switch (lease.leaseType) {
            case LEASE_TYPE_SALES:
                reducerSteps = this.props.salesSteps;
                isRedirect = this.props.salesIsRedirect;
                redirectStep = this.props.salesRedirectStep;
                break;
            case LEASE_TYPE_PROPERTY_MANAGEMENT:
                reducerSteps = this.props.pmSteps;
                isRedirect = this.props.pmIsRedirect;
                redirectStep = this.props.pmRedirectStep;
                break;
            default:
                reducerSteps = this.props.steps;
                isRedirect = this.props.isRedirect;
                redirectStep = this.props.redirectStep;
        }
        window.scrollTo(0, 0);
        //if backend return to redirect to another step(not next step) then redirect to that step.
        if (isRedirect) {
            nextStep = find(reducerSteps, { step: redirectStep });
        } else {
            if (isLeaseTypeResidentialTenancy(lease.leaseType)) {
                if (this.currentStep.name === 'summary') {
                    if (this.shouldSkipConnections()) {
                        // If we are on the summary page and connections are enabled
                        nextStep = getStepViaName('woohoo', reducerSteps);
                    } else if (!this.shouldSkipConnections()) {
                        nextStep = getStepViaName('connections', reducerSteps);
                    }
                } else {
                    nextStep = reducerSteps[this.currentStepNumber + 1];
                }
            } else {
                nextStep = reducerSteps[this.currentStepNumber + 1];
            }
        }
        return nextStep;
    }

    get isWhiteBackground() {
        if (isLeaseTypePm(this.props.lease.leaseType) && isLeaseLocationSA(this.props.lease.location)) {
            return true;
        }
        return false;
    }

    getCorrectBackground() {
        if (isLeaseTypeSales(this.props.lease.leaseType) && isLeaseLocationQLD(this.props.lease.location)) {
            return IMAGE_NEW;
        } else if (this.isWhiteBackground) {
            return GRAY_BACKGROUND;
        }
        return IMAGE;
    }
    getCorrectConfirmedBackground() {
        if (isLeaseTypeSales(this.props.lease.leaseType) && isLeaseLocationQLD(this.props.lease.location)) {
            return IMAGE_NEW;
        } else if (this.isWhiteBackground) {
            return GRAY_BACKGROUND;
        }
        return CONFIRMED;
    }

    get backgroundStyle() {
        if (this.currentStep.status === STEP_STATE_STATUS_DECLINE) {
            return DECLINE;
        }
        if (isLeaseTypeResidentialTenancy(this.props.lease.leaseType)) {
            if (this.state.displayConfirmedStyle) {
                if (this.currentStep.status === STEP_STATE_STATUS_CONFIRMED) {
                    return CONFIRMED;
                }
            }
            switch (this.currentStepNumber) {
                case 14: {
                    if (
                        !!this.props.changeRequestList.length &&
                        !checkIfChangeRequestsAreResolved(this.props.changeRequestList)
                    ) {
                        return DECLINE;
                    }

                    return this.getCorrectBackground();
                }
                default:
                    return this.getCorrectBackground();
            }
        }
        if (isLeaseTypeSales(this.props.lease.leaseType)) {
            if (this.state.displayConfirmedStyle) {
                if (this.currentStep.status === STEP_STATE_STATUS_CONFIRMED) {
                    return this.getCorrectConfirmedBackground();
                }
            }
            switch (this.currentStepNumber) {
                case 13: {
                    if (
                        !!this.props.changeRequestList.length &&
                        !checkIfChangeRequestsAreResolved(this.props.changeRequestList)
                    ) {
                        return DECLINE;
                    }

                    return this.getCorrectBackground();
                }
                default:
                    return this.getCorrectBackground();
            }
        }
        if (isLeaseTypePm(this.props.lease.leaseType)) {
            if (this.state.displayConfirmedStyle) {
                if (this.currentStep.status === STEP_STATE_STATUS_CONFIRMED) {
                    return this.getCorrectConfirmedBackground();
                }
            }
            switch (this.currentStepNumber) {
                case 14:
                case 15:
                case 16:
                case 17: {
                    if (this.currentStep.status === STEP_STATE_STATUS_CONFIRMED) {
                        return this.getCorrectConfirmedBackground();
                    }
                    return this.getCorrectBackground();
                }
                case 19: {
                    if (
                        !!this.props.changeRequestList.length &&
                        !checkIfChangeRequestsAreResolved(this.props.changeRequestList)
                    ) {
                        return DECLINE;
                    }
                    return this.getCorrectBackground();
                }
                default:
                    return this.getCorrectBackground();
            }
        }
        if (isLeaseTypeCommercialLease(this.props.lease.leaseType)) {
            if (this.state.displayConfirmedStyle) {
                if (this.currentStep.status === STEP_STATE_STATUS_CONFIRMED) {
                    return CONFIRMED;
                }
            }
            switch (this.currentStepNumber) {
                case 11: {
                    if (
                        !!this.props.changeRequestList.length &&
                        !checkIfChangeRequestsAreResolved(this.props.changeRequestList)
                    ) {
                        return DECLINE;
                    }
                    return this.getCorrectBackground();
                }
                default:
                    return this.getCorrectBackground();
            }
        }
        return this.getCorrectBackground();
    }

    shouldSkipConnections = () => {
        // If we are on the summary page and the connections do not exist OR are not enabled OR the current user
        // is not the primary tenant then skip the next step
        if (this.currentStep.name === 'summary') {
            if (
                !has(this.props.lease, 'connections') ||
                isEmpty(this.props.lease.connections) ||
                !this.props.lease.connections.connectionsEnabled ||
                this.props.lease.connections.connectionsSubmitted ||
                this.props.lease.connections.connectionCompany.disabled
            ) {
                return true;
            }

            if (!this.props.user.isPrimaryTenant) {
                return true;
            }
        }

        return false;
    };

    declineButtonHandler() {
        this.showModalRequest();
    }

    enterLocationManually() {
        this.showModalLocation();
    }

    confirmButtonHandler(payload) {
        this.setState({
            displayConfirmedStyle: false,
        });
        let data = {};
        if (this.currentStep.name === 'connections') {
            // If we are on the connections page, submit to a different endpoint
            let data = { ...payload };
            this.props.submitConnections(data);
        } else {
            // Every other page submit to this endpoint
            data = payload
                ? { step: this.currentStep.step, stepName: this.currentStep.name, payload: payload }
                : { step: this.currentStep.step, stepName: this.currentStep.name, payload: {} };
            this.props.confirmStep(data);
        }

        /*
         * If we re confirming step, wait for the response, so we
         * can display the appropriate error if needed.
         * */
        this.moveToNextStep();
    }

    moveToNextStep() {
        let isConfirmingRedirectStepName = 'isConfirmingRedirectStep';
        // wait 100ms to update redux and get the isConfirmingRedirectStep
        setTimeout(() => {
            if (isLeaseTypeSales(this.props.lease.leaseType)) {
                isConfirmingRedirectStepName = 'isConfirmingRedirectSalesStep';
            } else if (isLeaseTypePm(this.props.lease.leaseType)) {
                isConfirmingRedirectStepName = 'isConfirmingRedirectPmStep';
            }
            // wait for confirming next step from backend
            if (
                this.props[isConfirmingRedirectStepName] ||
                (this.props.isConfirmingStep && !this.props.showErrorModal && !this.props.showErrorPage)
            ) {
                this.moveToNextStep();
            } else {
                if (!this.props.showErrorModal && !this.props.showErrorPage) {
                    //Determine lease type
                    if (isLeaseTypeSales(this.props.lease.leaseType)) {
                        const nextSalesStep = this.nextStep(this.props.lease);
                        if (nextSalesStep) {
                            if (isLocationBaseURL(this.props.lease.leaseType, this.props.lease.location)) {
                                hashHistory.push(`/sales-steps/${this.props.lease.location}/${nextSalesStep.name}`);
                            } else {
                                hashHistory.push(`/sales-steps/${nextSalesStep.name}`);
                            }
                        }
                    } else if (isLeaseTypePm(this.props.lease.leaseType)) {
                        const nextPmStep = this.nextStep(this.props.lease);
                        if (nextPmStep) {
                            if (isLocationBaseURL(this.props.lease.leaseType, this.props.lease.location)) {
                                hashHistory.push(`/pm-steps/${this.props.lease.location}/${nextPmStep.name}`);
                            } else {
                                hashHistory.push(`/pm-steps/${nextPmStep.name}`);
                            }
                        }
                    } else if (isLeaseTypeCommercialLease(this.props.lease.leaseType)) {
                        const nextCommercialLeaseStep = this.nextStep(this.props.lease);
                        if (nextCommercialLeaseStep) {
                            hashHistory.push(
                                `/commercial-lease-steps/${this.props.lease.location}/${nextCommercialLeaseStep.name}`
                            );
                        }
                    } else {
                        const nextResTenStep = this.nextStep(this.props.lease);
                        if (nextResTenStep) {
                            if (isLocationBaseURL(this.props.lease.leaseType, this.props.lease.location)) {
                                hashHistory.push(`/steps/${this.props.lease.location}/${nextResTenStep.name}`);
                            } else {
                                hashHistory.push(`/steps/${nextResTenStep.name}`);
                            }
                        }
                    }

                    const nextStep = this.nextStep(this.props.lease);

                    // if there is no next step, that probably means we are at the end of the agreement, so dont push a next step
                    if (nextStep) {
                        this.props.setCurrentStep(this.props.lease, nextStep);
                    }
                    this.setState({ displayConfirmedStyle: true });
                }
            }
        }, 100);
    }

    getAddresses(string) {
        string = string.split(' ').join('+');
        this.props.getAddresses(string);
    }

    getFooter() {
        if (isLeaseTypeSales(this.props.lease.leaseType)) {
            return (
                <SalesFooter
                    step={this.currentStepNumber}
                    steps={this.steps}
                    setCurrentStep={this.props.setCurrentStep}
                    currentStepStatus={this.currentStep.status}
                    declineButtonHandler={() => this.declineButtonHandler()}
                    enterLocationManually={() => this.enterLocationManually()}
                    isConfirmingStep={this.props.isConfirmingStep}
                    confirmButtonHandler={(payload) => this.confirmButtonHandler(payload)}
                />
            );
        } else if (isLeaseTypePm(this.props.lease.leaseType)) {
            return !isLeaseLocationSA(this.props.lease.location) ? (
                <PmFooter
                    step={this.currentStepNumber}
                    steps={this.steps}
                    setCurrentStep={this.props.setCurrentStep}
                    currentStepStatus={this.currentStep ? this.currentStep.status : 'none'}
                    declineButtonHandler={() => this.declineButtonHandler()}
                    enterLocationManually={() => this.enterLocationManually()}
                    isConfirmingStep={this.props.isConfirmingStep}
                    confirmButtonHandler={(payload) => this.confirmButtonHandler(payload)}
                />
            ) : null;
        } else {
            return (
                <Footer
                    user={this.props.user}
                    step={this.currentStepNumber}
                    steps={this.steps}
                    setCurrentStep={this.props.setCurrentStep}
                    currentStepStatus={this.currentStep.status}
                    declineButtonHandler={() => this.declineButtonHandler()}
                    enterLocationManually={() => this.enterLocationManually()}
                    isConfirmingStep={this.props.isConfirmingStep}
                    confirmButtonHandler={(payload) => this.confirmButtonHandler(payload)}
                />
            );
        }
    }

    render() {
        if (!this.currentStep) {
            return null;
        }

        function getScreenSizeComponent(leaseType, location) {
            if (isLeaseTypePm(leaseType) && isLeaseLocationSA(location)) {
                return <RotateScreenWithSaPmFlag />;
            } else if (!isTouchScreen()) {
                return <ContinueOnMobile />;
            } else {
                return <RotateScreen />;
            }
        }

        if (this.props.lease) {
            const { showModalRequest, navigation, showModalLocation } = this.state;
            const { children } = this.props;
            const footer = this.getFooter();
            if (this.props.errorMessage === 'Unauthorized') {
                hashHistory.push('/');
            }
            if (this.props.lease === null) {
                return (
                    <div className={'wrap ' + this.backgroundStyle}>
                        <Preloader />
                    </div>
                );
            }

            return (
                <div className={this.isWhiteBackground ? GRAY_BACKGROUND : 'wrap ' + this.backgroundStyle}>
                    {getScreenSizeComponent(this.props.lease.leaseType, this.props.lease.location)}
                    <TwilioSyncUpdates />
                    <ChangeRequest
                        modalIsOpen={showModalRequest}
                        step={this.currentStepNumber}
                        hideModal={() => this.hideModalRequest()}
                        token={this.props.token}
                        refreshToken={this.props.refreshToken}
                        lease={this.props.lease}
                    />
                    <NewChangeRequest step={this.currentStep} />
                    <ChangeLocation
                        modalIsOpen={showModalLocation}
                        step={this.currentStepNumber}
                        hideModal={() => this.hideModalLocation()}
                    />
                    <Alert
                        modalIsOpen={this.props.showErrorModal}
                        hideModal={this.props.closePayloadAlert}
                        text="We lost the connection to our server. The internet is a bit slow. Please try again."
                    />
                    <Navigation
                        modalIsOpen={navigation}
                        hideNavigation={() => this.hideNavigation()}
                        setCurrentStep={(step) => this.props.setCurrentStep(step)}
                        menuItems={this.props.steps}
                    />
                    {/* For SA PMA (White background styles) we use new header inside the component */}
                    {!this.isWhiteBackground && (
                        <Header
                            step={this.currentStepNumber}
                            totalSteps={this.steps.length - 1}
                            showModal={() => this.showQuestion()}
                            showNavigation={() => this.showNavigation()}
                            lease={this.props.lease}
                            clauseList={this.props.clauseList}
                        />
                    )}
                    {!this.isWhiteBackground && this.currentStep.status === STEP_STATE_STATUS_DECLINE && (
                        <ChangeRequestInfo
                            step={this.currentStepNumber}
                            declineButtonHandler={() => this.declineButtonHandler()}
                        />
                    )}
                    {children}
                    {footer}
                </div>
            );
        } else {
            return null;
        }
    }

    hideModal() {
        this.setState({ showModalSign: false });
    }

    hideNavigation() {
        this.setState({ navigation: false });
    }

    showNavigation() {
        this.setState({ navigation: true });
    }

    hideModalRequest() {
        this.setState({ showModalRequest: false });
        this.props.setRequestMessage('');
    }

    showModalRequest() {
        this.setState({ showModalRequest: true });
    }

    hideModalLocation() {
        this.setState({ showModalLocation: false });
    }

    showModalLocation() {
        this.setState({ showModalLocation: true });
    }
}

export default connect(
    (state) => ({
        router: state.router,
        agreementInfo: state.main.agreementInfo,
        addresses: state.address.data,
        steps: state.steps.steps,
        salesSteps: state.salesSteps.salesSteps,
        pmSteps: state.pmSteps.pmSteps,
        isRedirect: state.steps.isRedirect,
        salesIsRedirect: state.salesSteps.isRedirect,
        pmIsRedirect: state.pmSteps.isRedirect,
        redirectStep: state.steps.redirectStep,
        salesRedirectStep: state.salesSteps.redirectStep,
        pmRedirectStep: state.pmSteps.redirectStep,
        isConfirmingRedirectStep: state.steps.isConfirmingRedirectStep,
        isConfirmingRedirectSalesStep: state.salesSteps.isConfirmingRedirectSalesStep,
        isConfirmingRedirectPmStep: state.pmSteps.isConfirmingRedirectPmStep,
        lease: state.main.lease,
        user: state.main.user,
        token: state.main.smsToken,
        refreshToken: state.main.refreshToken,
        errorMessage: state.main.errorMessage,
        isConfirmingStep: state.steps.isConfirmingStep,
        isTokenIdShift: state.main.isTokenIdShift,
        changeRequestList: state.steps.changeRequestList,
        showErrorPage: state.steps.showErrorPage,
        showErrorModal: state.steps.showErrorModal,
        initialWindowHeight: state.main.initialWindowHeight,
        commonSteps: state.commonSteps.steps,
    }),
    {
        login: tenantLogin.mobileLogin,
        getAddresses: tenantLogin.getAddresses,
        tenantSignature: tenantLogin.sendSignature,
        confirmStep: tenantLogin.confirmStep,
        submitConnections: tenantLogin.submitConnections,
        setCurrentStep: tenantLogin.setCurrentStep,
        setRequestMessage: tenantLogin.setRequestMessage,
        getAddressByCoordinates: tenantLogin.getAddressByCoordinates,
        getLeaseAgreementInformation: tenantLogin.getLeaseAgreementInformation,
        getSalesAgreementInformation: tenantLogin.getSalesAgreementInformation,
        getPmAgreementInformation: tenantLogin.getPmAgreementInformation,
        getFullPmAgreementInformation: tenantLogin.getFullPmAgreementInformation,
        getCommercialLeaseAgreementInformation: tenantLogin.getCommercialLeaseAgreementInformation,
        updateAgreement: tenantLogin.updateAgreement,
        closePayloadAlert: tenantLogin.closePayloadAlert,
        setInitialWindowHeight: tenantLogin.setInitialWindowHeight,
    }
)(Main);
