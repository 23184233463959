import { PdfGenerationStatus } from '../config';
import { UPDATE_DOCUMENT_SUCCESS } from './documents';

export const CUSTOM_LOGIN_REQUEST = 'CUSTOM_LOGIN_REQUEST';
export const CUSTOM_LOGIN_SUCCESS = 'CUSTOM_LOGIN_SUCCESS';
export const CUSTOM_LOGIN_FAILURE = 'CUSTOM_LOGIN_FAILURE';

export const UPDATE_ADDING_SIGNATURE_FROM_MOBILE = 'UPDATE_ADDING_SIGNATURE_FROM_MOBILE';

export const UPDATE_MOBILE_SIGNATURE_ADDED = 'UPDATE_MOBILE_SIGNATURE_ADDED';

export const UPDATE_DOCUMENT_COMPLETE = 'UPDATE_DOCUMENT_COMPLETE';

export const UPDATE_DOCUMENT_INVITE = 'UPDATE_DOCUMENT_INVITE';

export const SET_DESKTOP_SIGNING_STEP = 'SET_DESKTOP_SIGNING_STEP';

export const SET_PDF_GENERATED_STATE = 'UPDATE_PDF_GENERATED_STATE';

export const desktopSigningStep = {
    AGREE_TO_SIGN: 'AGREE_TO_SIGN',
    SCAN_QR_CODE: 'SCAN_QR_CODE',
    ADDING_SIGNATURE_FROM_MOBILE: 'ADDING_SIGNATURE_FROM_MOBILE',
    CONFIRM_SIGNATURE: 'CONFIRM_SIGNATURE',
    WITNESS_REQUIRED: 'WITNESS_REQUIRED',
};

export const SET_SELECTED_DOCUMENT_ID = 'SET_SELECTED_DOCUMENT_ID';

export const SET_IS_EDITING_PLACEHOLDERS = 'SET_IS_EDITING_PLACEHOLDERS';

export const SET_IS_QR_CODE_TEMPLATE = 'SET_IS_QR_CODE_TEMPLATE';

const initState = {
    isLoggedIn: false,
    user: null,
    invite: null,
    isTokenIdShift: false,
    documentToSign: null,
    desktopSigningStep: desktopSigningStep.AGREE_TO_SIGN,
    isAddingSignatureFromMobile: false,
    isMobileSignatureAdded: false,
    isWitnessSignRequired: false,
    isDocumentComplete: false,
    selectedDocumentId: null,
    isEditingPlaceholders: false,
    isQrCodeTemplate: false,
};

export default function customDocument(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case CUSTOM_LOGIN_REQUEST: {
            if (action.removeOldDocument) {
                newState.isLoggedIn = false;
                newState.isTokenIdShift = false;
                newState.errorMessage = null;
                newState.documentToSign = null;
            }
            break;
        }
        case CUSTOM_LOGIN_SUCCESS: {
            newState.smsToken = action.token;
            newState.isLoggedIn = true;
            newState.isTokenIdShift = true;
            newState.documentToSign = Object.assign({}, newState, action.result.data.document);
            newState.invite = Object.assign({}, newState, action.result.data.invite);
            const firstUnreadDocumentId = action.result.data.client?.uploadedDocumentsMeta?.find(
                (uploadedDocument) => !uploadedDocument.read
            )?.uploadedDocumentId;
            const lastDocumentId = action.result.data.client?.uploadedDocumentsMeta?.at(-1)?.uploadedDocumentId;
            newState.selectedDocumentId = firstUnreadDocumentId || lastDocumentId;
            break;
        }
        case CUSTOM_LOGIN_FAILURE: {
            newState.isLoggedIn = false;
            newState.isTokenIdShift = true;
            newState.errorMessage = action.error.response.data.error;
            break;
        }
        case UPDATE_DOCUMENT_SUCCESS: {
            newState.documentToSign = action.result.data.document;
            newState.invite = action.result.data.invite;
            break;
        }
        case UPDATE_ADDING_SIGNATURE_FROM_MOBILE: {
            newState.isAddingSignatureFromMobile = action.payload;
            break;
        }
        case UPDATE_MOBILE_SIGNATURE_ADDED: {
            newState.isMobileSignatureAdded = action.payload;
            break;
        }
        case UPDATE_DOCUMENT_COMPLETE: {
            newState.isDocumentComplete = action.payload;
            break;
        }
        case SET_DESKTOP_SIGNING_STEP: {
            switch (action.payload) {
                case desktopSigningStep.AGREE_TO_SIGN: {
                    newState.desktopSigningStep = desktopSigningStep.AGREE_TO_SIGN;
                    newState.isAddingSignatureFromMobile = false;
                    newState.isMobileSignatureAdded = false;
                    break;
                }
                case desktopSigningStep.WITNESS_REQUIRED: {
                    newState.desktopSigningStep = desktopSigningStep.WITNESS_REQUIRED;
                    newState.isAddingSignatureFromMobile = false;
                    newState.isMobileSignatureAdded = false;
                    newState.isWitnessSignRequired = true;
                    break;
                }
                case desktopSigningStep.SCAN_QR_CODE: {
                    newState.desktopSigningStep = desktopSigningStep.SCAN_QR_CODE;
                    newState.isAddingSignatureFromMobile = false;
                    newState.isMobileSignatureAdded = false;
                    break;
                }
                case desktopSigningStep.ADDING_SIGNATURE_FROM_MOBILE: {
                    newState.desktopSigningStep = desktopSigningStep.ADDING_SIGNATURE_FROM_MOBILE;
                    newState.isAddingSignatureFromMobile = true;
                    newState.isMobileSignatureAdded = false;
                    break;
                }
                case desktopSigningStep.CONFIRM_SIGNATURE: {
                    newState.desktopSigningStep = desktopSigningStep.CONFIRM_SIGNATURE;
                    newState.isAddingSignatureFromMobile = false;
                    newState.isMobileSignatureAdded = true;
                    break;
                }
            }
            break;
        }
        case SET_SELECTED_DOCUMENT_ID: {
            newState.selectedDocumentId = action.payload;
            break;
        }
        case UPDATE_DOCUMENT_INVITE: {
            newState.invite = action.updatedInvite;
            break;
        }
        case SET_IS_EDITING_PLACEHOLDERS: {
            newState.isEditingPlaceholders = action.payload;
            break;
        }
        case SET_PDF_GENERATED_STATE: {
            newState.documentToSign.pdfGeneratedState = action.payload;
            break;
        }
        case SET_IS_QR_CODE_TEMPLATE: {
            newState.isQrCodeTemplate = action.payload;
            break;
        }
    }

    return newState;
}
