/**
 * NOTE: Please do not add icons to this file directly. Instead, use the import-icon npm script
 */
import * as React from 'react';
import cx from 'classnames';

import { ReactComponent as InactiveCheckboxIcon } from './svgs/inactiveCheckboxIcon.svg';
import { ReactComponent as ActionRestartIcon } from './svgs/actionRestartIcon.svg';
import { ReactComponent as ActiveCheckboxIcon } from './svgs/activeCheckboxIcon.svg';
import { ReactComponent as ActiveRadioIcon } from './svgs/activeRadioIcon.svg';
import { ReactComponent as InactiveRadioIcon } from './svgs/inactiveRadioIcon.svg';
import { ReactComponent as ChevronDownIcon } from './svgs/chevronDownIcon.svg';
import { ReactComponent as CloseIcon } from './svgs/closeIcon.svg';
import { ReactComponent as MoreIcon } from './svgs/moreIcon.svg';
import { ReactComponent as AddIcon } from './svgs/addIcon.svg';
import { ReactComponent as DeleteIcon } from './svgs/deleteIcon.svg';
import { ReactComponent as MinusIcon } from './svgs/minusIcon.svg';
import { ReactComponent as CheckIcon } from './svgs/checkIcon.svg';
import { ReactComponent as UploadImageIcon } from './svgs/uploadImageIcon.svg';
import { ReactComponent as UploadedDocumentIcon } from './svgs/uploadedDocumentIcon.svg';
import { ReactComponent as DotIcon } from './svgs/dotIcon.svg';
import { ReactComponent as MobileIcon } from './svgs/mobileIcon.svg';
import { ReactComponent as EmailIcon } from './svgs/emailIcon.svg';
import { ReactComponent as InfoIcon } from './svgs/infoIcon.svg';
import { ReactComponent as ListIcon } from './svgs/listIcon.svg';
import { ReactComponent as MenuIcon } from './svgs/menuIcon.svg';
import { ReactComponent as UserIcon } from './svgs/userIcon.svg';
import { ReactComponent as PencilIcon } from './svgs/pencilIcon.svg';
import { ReactComponent as DocumentIcon } from './svgs/documentIcon.svg';
import { ReactComponent as ChevronRightIcon } from './svgs/chevronRightIcon.svg';
import { ReactComponent as CommentIcon } from './svgs/commentIcon.svg';
import { ReactComponent as EraserIcon } from './svgs/eraserIcon.svg';
import { ReactComponent as ChevronLeftIcon } from './svgs/chevronLeftIcon.svg';
import { ReactComponent as CloudIcon } from './svgs/cloudIcon.svg';
import { ReactComponent as SavingIcon } from './svgs/savingIcon.svg';
import { ReactComponent as SavingTextIcon } from './svgs/savingTextIcon.svg';
import { ReactComponent as CopyIcon } from './svgs/copyIcon.svg';
import { ReactComponent as EditIcon } from './svgs/editIcon.svg';
import { ReactComponent as EmptyDocumentIcon } from './svgs/emptyDocumentIcon.svg';
import { ReactComponent as MailIcon } from './svgs/mailIcon.svg';
import { ReactComponent as ResendIcon } from './svgs/resendIcon.svg';
import { ReactComponent as SuccessIcon } from './svgs/successIcon.svg';
import { ReactComponent as ClockTwoToneIcon } from './svgs/clockTwoToneIcon.svg';
import { ReactComponent as SuccessTwoToneIcon } from './svgs/successTwoToneIcon.svg';
import { ReactComponent as MailTwoToneIcon } from './svgs/mailTwoToneIcon.svg';
import { ReactComponent as EmptyDocumentTwoToneIcon } from './svgs/emptyDocumentTwoToneIcon.svg';
import { ReactComponent as EyeIcon } from './svgs/eyeIcon.svg';
import { ReactComponent as LinkIcon } from './svgs/linkIcon.svg';
import { ReactComponent as InactiveCheckboxDisabledIcon } from './svgs/inactiveCheckboxDisabledIcon.svg';
import { ReactComponent as InactiveRadioDisabledIcon } from './svgs/inactiveRadioDisabledIcon.svg';
import { ReactComponent as FLKIcon } from './svgs/FLKIcon.svg';
import { ReactComponent as HelpIcon } from './svgs/HelpIcon.svg';
import { ReactComponent as ErrorIcon } from './svgs/ErrorIcon.svg';
import { ReactComponent as LogoIcon } from './svgs/logoIcon.svg';
import { ReactComponent as WarningIcon } from './svgs/warningIcon.svg';
import { ReactComponent as DangerIcon } from './svgs/dangerIcon.svg';
import { ReactComponent as UndoIcon } from './svgs/undoIcon.svg';

import styles from './Icon.module.scss';

// If Icon is two tone then we can add id to it's elements and change color with css
// For that we can use following id's
// primary-fill - add only fill property in css with primary color
// secondary-fill - add only fill property in css with secondary color
// primary-fill-stroke - add fill and stroke property in css with primary color
// primary-stroke-secondary-fill - add stroke property and use primary color and add fill property and use secondary color

enum Icons {
    ACTION_RESTART,
    INACTIVE_CHECKBOX,
    ACTIVE_CHECKBOX,
    ACTIVE_RADIO,
    INACTIVE_RADIO,
    CHEVRON_DOWN,
    CLOSE,
    MORE,
    ADD,
    DELETE,
    MINUS,
    CHECK,
    UPLOAD_IMAGE,
    UPLOADED_DOCUMENT,
    DOT,
    MOBILE,
    EMAIL,
    INFO,
    LIST,
    MENU,
    USER,
    PENCIL,
    DANGER,
    DOCUMENT,
    CHEVRON_RIGHT,
    COMMENT,
    ERASER,
    CHEVRON_LEFT,
    CLOUD,
    SAVING,
    SAVING_TEXT,
    COPY,
    EDIT,
    EMPTY_DOCUMENT,
    MAIL,
    RESEND,
    SUCCESS,
    CLOCK_TWO_TONE,
    SUCCESS_TWO_TONE,
    MAIL_TWO_TONE,
    EMPTY_DOCUMENT_TWO_TONE,
    EYE,
    LINK,
    INACTIVE_CHECKBOX_DISABLED,
    INACTIVE_RADIO_DISABLED,
    FLK,
    HELP,
    ERROR,
    LOGO,
    WARN,
    UNDO,
}

enum IconSize {
    DEFAULT,
    SMALL,
    SMALLEST,
    LARGE,
    LARGER,
    LARGEST,
}

interface IconProps {
    icon: Icons;
    size?: IconSize;
    className?: string;
}

const getIcon = (icon: Icons) => {
    switch (icon) {
        case Icons.ACTION_RESTART:
            return ActionRestartIcon;
        case Icons.INACTIVE_CHECKBOX:
            return InactiveCheckboxIcon;
        case Icons.ACTIVE_CHECKBOX:
            return ActiveCheckboxIcon;
        case Icons.ACTIVE_RADIO:
            return ActiveRadioIcon;
        case Icons.INACTIVE_RADIO:
            return InactiveRadioIcon;
        case Icons.CHEVRON_DOWN:
            return ChevronDownIcon;
        case Icons.CLOSE:
            return CloseIcon;
        case Icons.MORE:
            return MoreIcon;
        case Icons.ADD:
            return AddIcon;
        case Icons.DELETE:
            return DeleteIcon;
        case Icons.MINUS:
            return MinusIcon;
        case Icons.CHECK:
            return CheckIcon;
        case Icons.UPLOAD_IMAGE:
            return UploadImageIcon;
        case Icons.UPLOADED_DOCUMENT:
            return UploadedDocumentIcon;
        case Icons.DOT:
            return DotIcon;
        case Icons.MOBILE:
            return MobileIcon;
        case Icons.EMAIL:
            return EmailIcon;
        case Icons.INFO:
            return InfoIcon;
        case Icons.LIST:
            return ListIcon;
        case Icons.MENU:
            return MenuIcon;
        case Icons.USER:
            return UserIcon;
        case Icons.PENCIL:
            return PencilIcon;
        case Icons.DOCUMENT:
            return DocumentIcon;
        case Icons.CHEVRON_RIGHT:
            return ChevronRightIcon;
        case Icons.COMMENT:
            return CommentIcon;
        case Icons.ERASER:
            return EraserIcon;
        case Icons.CHEVRON_LEFT:
            return ChevronLeftIcon;
        case Icons.CLOUD:
            return CloudIcon;
        case Icons.SAVING:
            return SavingIcon;
        case Icons.SAVING_TEXT:
            return SavingTextIcon;
        case Icons.COPY:
            return CopyIcon;
        case Icons.EDIT:
            return EditIcon;
        case Icons.EMPTY_DOCUMENT:
            return EmptyDocumentIcon;
        case Icons.MAIL:
            return MailIcon;
        case Icons.RESEND:
            return ResendIcon;
        case Icons.SUCCESS:
            return SuccessIcon;
        case Icons.CLOCK_TWO_TONE:
            return ClockTwoToneIcon;
        case Icons.SUCCESS_TWO_TONE:
            return SuccessTwoToneIcon;
        case Icons.MAIL_TWO_TONE:
            return MailTwoToneIcon;
        case Icons.EMPTY_DOCUMENT_TWO_TONE:
            return EmptyDocumentTwoToneIcon;
        case Icons.EYE:
            return EyeIcon;
        case Icons.LINK:
            return LinkIcon;
        case Icons.INACTIVE_CHECKBOX_DISABLED:
            return InactiveCheckboxDisabledIcon;
        case Icons.INACTIVE_RADIO_DISABLED:
            return InactiveRadioDisabledIcon;
        case Icons.FLK:
            return FLKIcon;
        case Icons.HELP:
            return HelpIcon;
        case Icons.ERROR:
            return ErrorIcon;
        case Icons.LOGO:
            return LogoIcon;
        case Icons.DANGER:
            return DangerIcon;
        case Icons.WARN:
            return WarningIcon;
        case Icons.UNDO:
            return UndoIcon;
        default:
            throw new Error(`An invalid icon prop ${icon} was passed to the Icon component.`);
    }
};

const Icon: React.FC<IconProps> = ({ icon, className, size = IconSize.DEFAULT, ...rest }) => {
    const IconComponent = getIcon(icon);

    return (
        <IconComponent
            className={cx(styles.icon, className, {
                [styles.small]: size === IconSize.SMALL,
                [styles.smallest]: size === IconSize.SMALLEST,
                [styles.large]: size === IconSize.LARGE,
                [styles.larger]: size === IconSize.LARGER,
                [styles.largest]: size === IconSize.LARGEST,
            })}
            {...rest}
        />
    );
};

export { Icon, Icons, IconSize };
