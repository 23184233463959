import { AnyAction } from 'redux';

export const SET_DETAILS = 'SET_DETAILS';

// enum VerificationStep {

// }

export interface ConnectIdState {
    code?: string;
    state?: string;
    iss?: string;
}

const initialState: ConnectIdState = {};

const connectIdReducer = (state = initialState, action: AnyAction): ConnectIdState => {
    switch (action.type) {
        case SET_DETAILS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default connectIdReducer;
