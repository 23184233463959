import { Agency } from './Agency';
import { Agent } from './Agent';
import { AddressDetails } from './Common';
import { DEFINE_ME, DateString } from './utilityTypes';
import { desktopSigningStep } from '../reducers/customDocument.js';

export type UploadedDocument = {
    pdfGeneratedState: string;
    annotations: string;
    annotationsCount: string;
    witnessAnnotationsCount: string;
    id: string;
    document: {
        category: string;
        leaseType: string;
        agencyDefault: boolean;
        id: string;
        created: DateString;
        updated: DateString;
        documentName: string;
        size: number;
        file: string;
        fileName: string;
    };
    optimisedPdfFile: boolean;
    confirmedPdf: string;
    insertedSignaturesPdf: string;
};

export type ClientUploadedDocumentMeta = {
    read: boolean;
    id: string;
    uploadedDocumentId: string;
    parentDocumentId: string;
    documentName: string;
};

export enum VerificationStatus {
    PENDING = 'pending',
    VERIFIED = 'verified',
    FAILED = 'failed',
}

export type Client = {
    id: string;
    index?: number;
    name: string;
    email: string;
    phone: string;
    source: string;
    signingOrder?: number;
    uploadedDocumentsMeta?: ClientUploadedDocumentMeta[];
    acknowledgedDate?: DateString;
    verifiedProofOfIdentity?: VerificationStatus;
    requireProofOfIdentity?: boolean;
    signature?: {
        signedDate: string;
        ip: string;
        signedDateFormatted: string;
        signedDateFormattedForPdf: string;
        id: string;
    };
    hasWitnessSignature?: boolean;
    tempSignature?: {
        base64SignatureImage: string;
        witness?: {
            base64SignatureImage: string;
        };
    };
};

export enum CustomPlaceholderRespondentType {
    SENDER = 'sender',
    CLIENT = 'client',
}

export type CustomPlaceholder = {
    id: string;
    label: string;
    value?: string;
    respondentType: CustomPlaceholderRespondentType;
    count: number;
};

type ListNow = {
    enabled?: boolean;
    address?: string;
    budget?: number;
    addressDetails?: AddressDetails;
};

export enum DeliveryType {
    EMAIL = 'email',
    SMS = 'sms',
    QR_CODE = 'qr_code',
}

export enum UadSigningSteps {
    VERIFY_IDENTITY = 'verify_identity',
    REQUIRED_INFO = 'required_info',
    REVIEW_SIGN = 'review_sign',
}

// This is an in-progress type definition
export type UploadADoc = {
    id: string;
    additionalCharges: {
        requiresApproval: boolean;
        bulkSendApprovalStatus: string;
    };
    isSentViaContactList: boolean;
    createdViaEmail: boolean;
    docType: string;
    pdfGeneratedState: string;
    locked: boolean;
    visibilityList: DEFINE_ME[]; // Could be an array of specific types if known
    signingClients: DEFINE_ME[]; // Could be an array of specific types if known
    documentTitle: string;
    status: string;
    sentForSigning?: DateString;
    firstSentForSigning: null;
    agency: Agency;
    agent: Agent;
    documentLink?: DEFINE_ME;
    client?: {
        currentSigningClientOrder: number;
        id: string;
        clients: Client[];
    };
    documents: {
        category: string;
        leaseType: string;
        agencyDefault: boolean;
        id: string;
        documentName: string;
        size: number;
        file: string;
        fileName: string;
    }[];
    uploadedDocuments: UploadedDocument[];
    created: DateString;
    updated: DateString;
    auditTrail: DEFINE_ME[]; // Could be an array of specific types if known
    acknowledgementText: string;
    confirmationType: string;
    deadline: number;
    deliveryType?: DeliveryType;
    descriptionText: string;
    executionType: string;
    hasAgentSignatureAnnotations?: boolean;
    acknowledgedDate?: DateString;
    customPlaceholders?: CustomPlaceholder[];
    integrationPropertyId?: string;
    address?: string;
    listNow?: ListNow;
    enforceSequentialSigning?: boolean;
    hasClientCustomPlaceholders?: boolean;
};

export type Witness = {
    firstName?: string;
    lastName?: string;
    witnessAcknowledgement?: boolean;
};

export enum DesktopSigningStep {
    AGREE_TO_SIGN = desktopSigningStep.AGREE_TO_SIGN,
    SCAN_QR_CODE = desktopSigningStep.SCAN_QR_CODE,
    ADDING_SIGNATURE_FROM_MOBILE = desktopSigningStep.ADDING_SIGNATURE_FROM_MOBILE,
    CONFIRM_SIGNATURE = desktopSigningStep.CONFIRM_SIGNATURE,
    WITNESS_REQUIRED = desktopSigningStep.WITNESS_REQUIRED,
}
