export const SPECIAL_CONDITIONS_INITIAL_STEP = 'specialConditionsInitial';
export const AGENT_CONDITIONS_STEP = 'agent_conditions';
export const MATERIAL_FACT_STEP = 'materialFact';
export const AGREEMENT_STEP = 'agreement';
export const TENANT_SIGNATURE_STEP = 'tenantSignature';
export const TENANT_CONFIRMATION_STEP = 'tenantConfirmation';
export const WITNESS_SIGNING_STEP = 'witnessSigning';
export const DISCLOSURE_STEP = 'disclosure';
export const PM_AGREEMENT_STEP = 'pmStandardTerms';
export const SALES_AGREEMENT_STEP = 'standard_terms_conditions';

export const SALES_REBATES_STEP = 'rebates';
export const SALES_OWNER_ID_STEP = 'owner_id';
export const SALES_OWNER_SIGN_STEP = 'owner_signs';

export const AGENCY_ACCOUNT_TYPE = 'agency_account_type';
export const GENERAL_ACCOUNT_TYPE = 'general_account_type';

export const CONFIRMATION_TYPE_SIGN = 'sign';
export const CONFIRMATION_TYPE_ACKNOWLEDGE = 'acknowledge';

export const SIGN_VERB = 'signing';
export const ACKNOWLEDGE_VERB = 'acknowledging';

export const EXECUTION_TYPE_EMBEDDED_SIGNATURES = 'Embedded signatures';
export const IDENTIFICATION_HEADER_1 =
    "We are required to confirm your identification, to proceed please ensure that you take a clear image of your photo ID. E.g. driver's license or passport.";
export const IDENTIFICATION_HEADER_2 = 'Your image will not be stored on our servers.';

export enum UploadADocRoutes {
    VERIFY = '/custom-document/:token/verify',
    DETAILS = '/custom-document/:token/details',
    SIGN = '/custom-document/:token/sign/:uploadedDocId',
    WELCOME = '/custom-document/:token',
    FORM = '/custom-document/:token/form',
}

export const buildUploadADocRoute = (route: UploadADocRoutes, token: string, uploadedDocId?: string) => {
    switch (route) {
        case UploadADocRoutes.DETAILS:
        case UploadADocRoutes.WELCOME:
        case UploadADocRoutes.FORM:
        case UploadADocRoutes.VERIFY:
            return route.replace(':token', token);
        case UploadADocRoutes.SIGN:
            return route.replace(':token', token).replace(':uploadedDocId', uploadedDocId || '');
        default:
            return '';
    }
};

export const COMPLIANT_TYPE_COMPLIANT = 'Compliant';
export const COMPLIANT_TYPE_NON_COMPLIANT = 'Non Compliant';
export const COMPLIANT_TYPE_UNKNOWN = 'Unknown';

export const YES_VALUE = 'yes';
export const NO_VALUE = 'no';

export const DOCUMENT_INVITE_CANCELLED = 'Document Invite was cancelled';
